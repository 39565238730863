import { gql } from "@apollo/client"

import { useMeQuery } from "lib/graphql"

export const ME_FRAGMENT = gql`
  fragment Me on User {
    id
    firstName
    lastName
    fullName
    avatar
    email
    access
    role
    language
    companyEntities {
      id
    }
  }
`

export const ME = gql`
  query Me {
    me {
      ...Me
    }
  }
`

export function useMe() {
  const res = useMeQuery({ fetchPolicy: "cache-first" })
  return { me: res.data?.me, ...res }
}
